@font-face {
  font-family: "IBM plex mono";
  src: url("../fonts/ibm-plex-mono/IBMPlexMono-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "IBM plex mono Italic";
  src: url("../fonts/ibm-plex-mono/IBMPlexMono-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "IBM plex mono Bold";
  src: url("../fonts/ibm-plex-mono/IBMPlexMono-Bold.woff2") format("woff2");
  font-weight: bold;
}

.ibm-plex-italic{
  font-family: "IBM plex mono Italic";
}
