.navbar {
  &:focus-within {
    .navbar-collapse {
      @extend .d-block;
    }
  }

  .dropdown {
    .dropdown-menu {
      @extend .d-none;
    }

    &:hover,
    &:focus-within {
      .dropdown-menu {
        @extend .d-block;
      }
    }
  }
}
.logo {
  svg {
    width: 10rem;
  }
}
