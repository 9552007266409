$debug: false;
$vendor-prefixes: ("", "-webkit-", "-ms-", "-o-", "-moz-");
$bezier: cubic-bezier(0.75, 0, 0.25, 1);

html,body {
  font-size: 87%;
  list-style: none;
  font-family: 'IBM Plex Mono', monospace !important;
  color: #fff !important;
 }
$font-family-sans-serif: 'IBM Plex Mono', monospace;

$fucsia: #e100fd;
$black: #000;
$white: #fff;

$theme-colors: (
  "pirata": $black,
  "primary": $white,
  "secondary":$fucsia,
  "transparent": transparent
);

$colors: (
  "fucsia": $fucsia,
  "black": $black,
  "white": $white
);

$opacities: (
  0: -100%,
  1: -80%,
  2: -60%,
  3: -40%,
  4: -20%,
  5: 0%
);

$enable-responsive-font-sizes: true;

$spacer: 1rem !default;
$spacers: (
  1: ($spacer * 1),
  2: ($spacer * 2),
  3: ($spacer * 3),
  4: ($spacer * 4),
  5: ($spacer * 5),
  6: ($spacer * 6),
  7: ($spacer * 7),
  8: ($spacer * 8),
  9: ($spacer * 9),
  10: ($spacer * 10),
);

/// Tamaños (anchos y altos) redefinidos desde Bootstrap.
$sizes: (
  10: 10%,
  20: 20%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  80: 80%,
  90: 90%,
  20px: 20px,
  50vh: 50vh,
  75vh: 75vh,
  100vh: 100vh,
  100vw: 100vw
);

/*
 * Redefinir variables de Boostrap acá.  Se las puede ver en
 * node_modules/bootstrap/scss/_variables.scss
 */

/*
 * XXX: Solo importar los archivos necesarios de bootstrap para
 * facilitar la reducción de CSS.  Por ahora pedimos todo para poder
 * empezar a trabajar en el HTML sin pensar en CSS.
 */
@import "bootstrap/scss/bootstrap";
@import "fork-awesome/scss/fork-awesome";
@import "accessibility";
@import "helpers";
@import "toggler";
@import "share_box";
@import "embed";
@import "snap";
@import "editor";
@import "menu";
@import "toggler";
@import "footer";
@import "fonts";
@import "home";


article {
  a {
    text-decoration: underline;
  }
}
a:hover {
  color: $fucsia;
}
img {
  width: 100% !important; 
}