footer {
margin-bottom: 0;

  nav {
    font-size: $h5-font-size;
    ul {
      @include list-unstyled();
      li {
      }
    }
  }

  #credits {
    font-size: .8rem;
    margin: 0;

    a {
      @extend .font-weight-bold;
      color: $fucsia;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
